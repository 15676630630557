.step {
    margin-top:50px;
}

.contexts {
	margin-top:100px;
}

.youtube {
    width:100%;
    height:100%;
}

.youtube-container{
    height:500px;
}