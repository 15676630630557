.signup-modal {
  text-align: center;
  font-family: minion-pro,serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0em;
  line-height: 2.2em;
  text-transform: none;
  color: rgb(41, 41, 41);
  z-index: 1300;
}
.notice-modal {
  text-align: center;
  font-family: minion-pro,serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0em;
  line-height: 2.2em;
  text-transform: none;
  color: rgb(41, 41, 41);
  z-index: 1200;
}
.home-youtube {
    width:100%;
    height:100%;
}

.home-youtube-container{
    height:500px;
    margin-top:50px;
}