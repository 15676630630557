.react-datepicker-wrapper {
    display: block;
}

.appointment-form {
    margin-top:2rem;
    text-align: center;
}

.appointment-form-row {
    margin-top:1rem;
}

.contact-phone {
    margin-top:30px;
}

.info h3 {
    font-size:1.25rem;
    margin-bottom:2rem;
}

.info {
    margin-top:2rem;
    text-align:left;
}

.info-row {
    margin-bottom:1rem;
}

.checkboxs {
    text-align:left;
}

.submission-message-box {
    margin:0.5rem;
    text-align: center; 
    margin: 1em 0;
}

.submission-message {
  vertical-align: middle;
}

.helper {
  display: inline-block;
  height: 40%;
  vertical-align: middle;
}