.pricing-type {
    font-style: italic;
    font-size: 20px;
    font-weight: bold;
}

.pricing-item {
	font-weight:400;
	font-size:15px;
}

.pricing-group{
	margin-top:100px;
}

.contexts{
	margin-top:100px;
}

.pricing-image {
	min-width: 250px;
}