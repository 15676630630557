.App {
  text-align: center;
  font-family: minion-pro,serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0em;
  line-height: 2.2em;
  text-transform: none;
  color: rgb(41, 41, 41);
}

.App-logo {
  max-width: 260px;
}

.App-header {
  height: 115px;
  padding: 20px;
}