.nav-head {
    font-family: minion-pro,serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.02em;
    font-size: 14px;
}

.nav-item {
    color: rgba(77,77,77,.68);
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
}

.nav-center{
    margin: 0 auto;
}