.instagram-photo-box {
    margin:0.5rem;
    text-align: center; margin: 1em 0;
}

.instagram-photo {
  vertical-align: middle;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}